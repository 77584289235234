// import React from 'react'

// const style = {
//   num: "block my-2"
// }
// import Underseventeen from "../../../assets/underseventeen.jpg"
export const tournamentData = [
  {
    title: 'The Centers',
    desc: 'Our events areas',
    content: [
      {
        ruleTitle: "COMING SOON ",
        ruleDesc: "All informations will be communicated here",
      },
      // {
      //   ruleTitle: "",
      //   subRule1: <p><b className={style.num}>2.1</b></p>,
      //   subRule3: <p><b className={style.num}>2.3</b></p>,
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   subRule1: <p><b className={style.num}>4.1</b></p>,
      // },
      // {
      //ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   subRule1: <p><b className={style.num}>6.1</b></p>,
      //   subRule2: <p><b className={style.num}>6.2</b></p>,
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      //   subRule1: <p><b className={style.num}>9.1</b></p>,
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      // },
      // {
      //   ruleTitle: "",
      //   subRule1: <p><b className={style.num}>13.1</b></p>,
      //   subRule2: <p><b className={style.num}>13.2</b></p>,
      // },
      // {
      //   ruleTitle: "",
      //   ruleDesc: "",
      //   subRule1: <p><b className={style.num}>14.1</b></p>,
      // },
    ],
    id: "centers_1_abcd",
  },
  {
    title: 'Participating Schools',
    desc: 'Tournament participants',
    content: [
      {
        titleMain: "IMPORTANT NOTICE:",
        titleName: "Kickoff for the U-17 Boy's NAPPS inter school tournament will be on October 19th - October 26th 2024 at Yaba College of Technology Lagos. GET READY!!!!",
        image: "../../../assets/underseventeen.jpg",

      },
      {
        ruleTitle: " U-17 boy's football tournament.",
        subRule: "The List of International School (AISEN). That's expected to participate in this year's 2024, Africa Football Empowerment Congress (AFEC).",
        ruleDesc: "James Hope college.",
      },
      {
        ruleDesc: "Grange School",
      },
      {
       ruleDesc: "Avi-Cenna international school"
      },
      {
        ruleDesc: "Lagos Preparatory school",
      },
      {
        ruleDesc: " British international school",
      },
      {
        ruleDesc: "Green springs school",
      },
      {
        ruleDesc: "Temple school",
      },
      {
        ruleDesc: "Lekki British school",
      },
      {
        ruleDesc: "ChrisLand school",
      },
      {
        ruleDesc: "Caleb British international school",
      },
      {
        ruleDesc: "Queensland College"
      },
      {
        ruleDesc: "Corona school"
      },
      {
        ruleDesc: "Down College"
      },
      {
        ruleDesc: "Atlantic Hall"
      },
      {
        ruleDesc: "Meadow Hall"
      },
      {
        ruleDesc: "Children international school"
      },
      {
        ruleDesc: "Greenville school"
      },
      {
        ruleDesc: "American international school"
      },
      {
        ruleDesc: "St. Saviour school"
      },
      {
        ruleDesc: "Charterhouse Lagos"
      },
      {
        ruleDesc: "River bank"
      },
      {
        ruleDesc: "Rainbow college"
      },
      {
        ruleDesc: "Emerald school"
      },
      {
        ruleDesc: "New Hall international school"
      },
      {
        ruleDesc: "Redeemers international secondary school"
      },
      {
        ruleDesc: "Day waterman"
      },
      {
        ruleDesc: "Lagoon school"
      },
    ],
    id: "centers_1_abcghy",
  }
]















