import React from "react"

const style = {
  num: "block my-2"
}

export const participantsData = [
  {
    title: "Tournament regulations",
    desc: "Rules of the match",
    content: [
      {
        ruleTitle: "1. Rules AFRICA Football",
        ruleDesc: "Empowerment Congress cup will be played in accordance with applicable sections of the FIFA rules and regulation. When subject to interpretation of FIFA rules and regulations",
      },
      {
        ruleTitle: "2. Competition rules",
        subRule1: <p><b className={style.num}>2.1</b> Any football team is free to register in this tournament programme e.g. School teams can parti cipate and football club that register National Football  Association can participate too. All players must be registered with the club they are playing for. Please note that the player cannot register for more than one team in the tournament.</p>,
        subRule2: <p><b className={style.num}>2.2</b> The (40) teams will be divided into groups of TEN(10) From group (A-J). Each  group consist of 4 teams. Three (3) points will be awarded for a win, 1 point for  draw and 0 points for a loss. If two or more teams have the same number of points in the same group, the  order will be decided as follows: * Goal difference  * Highest number of goals scored * Results in the match(es) between teams with equal points (head to head) * Penalty shoot-out according to the FIFA rules</p>,
        subRule3: <p><b className={style.num}>2.3</b> All playoff games will be advised by the cup system, i.e. two teams will qualify from each group e.g. A-H to last sixteen (16) according to FIFA rules The two teams that tpgroup A, will play the Two teams that top group B. The two teams that top group group C will play with D. Group E will play F. Group F will play H.</p>,
      },
      {
        ruleTitle: "3. Playing Period",
        ruleDesc: "First half 30 minutes and second half 30 minutes.",
      },
      {
        ruleTitle: "4. Numbers of players and substitution.",
        subRule1: <p><b className={style.num}>4.1</b> Each team can register 20 players with 4 officials, teams have the right to substitute 4 players in a match, eleven players on the pitch and Four substitutes, A substituted player will not re-enter in the match. Substitutions can't be done without stopping the match. Notice that the match has to be stopped and the referee has to be informed at the substitution of a player. All substitutions should be done within the technical area of the team. If there is no technical area the substitution should be made at the center line. The player that will be substituted has to leave the pitch before the new player can enter the pitch. Incorrect substitutions will be punished with a yellow card.</p>,
      },
      {
        ruleTitle: "5. Proof of participant list.",
        ruleDesc: "Each team must submit a list of players to the AFRICA Football Empowerment Congress administration before their match in the tournament. All players MUST be able to identify their selves with their playing card",
      },
      {
        ruleTitle: "6. Match information.",
        subRule1: <p><b className={style.num}>6.1</b> It is not permited to use a player from the same club in several teams. If notice, the team will pay fine of N20,000 only to the officials.</p>,
        subRule2: <p><b className={style.num}>6.2</b> The number of each player has the team list must be the same as the number on the jersey. All players on the team list are considered as if they have participated in the tournament.Random controls of team list and participant list will be carried out by (AFEC) Officials. All teams officials (maximum 4) have to be indicated with their names on the team list. Only these officials are allowed to be in the technical area. If there are no markings for the technical area it is considered to be an area of 8 meters along the side line where the substitutes are located.</p>,
      },
      {
        ruleTitle: "7. Player Uniforms.",
        ruleDesc: "All team jersey must be numbered, and the numbers must correspond to the numbers on the team list. Numbers must be on the back of the jersey. Two players may not use the same number in any given match.If the referee decides that one team should change jersey due to similarity to the opposing team's jersey, the team appearing last in the match schedule has to change.",
      },
      {
        ruleTitle: "8. Before, during and after the match",
        ruleDesc: "All teams should be ready at the pitch no later than 15 minutes before kick-off.The team manager make sure that his or her player are adequately insured. All players must wear shin guards.The teams are responsible for their supporter's conduct, bad supporter's behavior or conduct will results in the teams disqualification or reduction of points. The team's official (coach) must immediately after the match sign the referee match card in order to confirm the match result, red cards and other events during the match. Failure to sign may result in a notification to the AFRICA FOOTBALL EMPOWERMENT CONGRESS official's competition jury.",
      },
      {
        ruleTitle: "9. Balls",
        ruleDesc: "All the matches are played using ball size 5.",
        subRule1: <p><b className={style.num}>9.1</b> Match ball: each team shall provide at least a match ball for each match</p>,
      },
      {
        ruleTitle: "10. Tournament jury",
        ruleDesc: "The jury of the tournament consist of representatives of the AFRICA FOOTBALL EMPOWERMENT CONGRESS OFFICIALS. The jury handles protests and sanction matters. The decision of the jury cannot be appealed.",
      },
      {
        ruleTitle: "11. Protests and Sanctions",
        ruleDesc: "Any protests must be made in writing and delivered by a responsible to tournament, no later than 48 hours after the match is finished.A protest fee of 5,000 naira only will be paid at the same time. This fee will be returned if the protest is approved.The decisions of the referee regarding facts connected with the match are final (FIFA rule No. 5). No measures will be taken on protests on decisions made by the referee.",
      },
      {
        ruleTitle: "12. Cautions and sending-off offences",
        ruleDesc: "Any player given a red card is automatically suspended from participation in the following match and the team will pay N1000 only.The tournament jury can decide about suspension for further matches in case of a direct red card.Yellow cards will pay N500 only and not accumulated.Each team has the responsibility that a suspended does not participate in matches where is suspended.Severe offences will be reported to the FA of the nation IMPORTANT NOTE: If a player that is suspended participates in a match, the team with the suspended player automatically loses the match with 0-3.",
      },
      {
        ruleTitle: "13. Walk over, abandoned match",
        subRule1: <p><b className={style.num}>13.1</b> Any team not appearing at the appointed time and place for a match can be declared loser by 0-3, after a decision by the jury. If a team repeatedly fails to appear at the matches, the team may be excluded from the tournament or Fine of Ten Thousand Naira (N10,000)In special cases, the tournament jury may decide to exclude a team at the first offence i.e. if a deliberate walk over favors the own team or is unfair to another team in the group.</p>,
        subRule2: <p><b className={style.num}>13.2</b> If a match Is interrupted for some reason and can't be played to the end, the jury will decide if the match has to be replayed and the organizers have the right to change the time, date and venue of any match if the need arise decided by penalty shoot-out or restarted from the minute when it was interrupted, or if the result will be fixed or if one of the teams or both teams lose 0-3.</p>,
      },
      {
        ruleTitle: "14. Insurance and responsibility",
        ruleDesc: "By registering to participate in the tournament, the registrant (who is the team manager in charge or someone else tasked by the team manager in charge) verifies that the, team manager in charge has been made aware of these stipulations and that the team manager in charge has the necessary authority and permission from the participants, the participants' guardians and the registered association. Participation in the tournament is at the participants' own risk and the team manager in charge is responsible for all participants whom the team manager has registered which should be included in the list of participants.",
        subRule1: <p><b className={style.num}>14.1</b> The team manager in charge is obligated to ensure that all participants are insured on the pitch. Each participant is responsible for his or her personal lost of property.The tournament organizers are not responsible for compensation and they are also not responsible for financial losses or damages that may occur, such as war-like events, fire, flood, interruptions in public transports connections, government actions, strike or other similar circumstances.Best Regards,</p>,
      },
    ],
    id: "tournament_1_abc",
  },
  {
    title: "Tournament Information",
    desc: "The most important information about the tournament",
    content: [
      {
        ruleTitle: "IMPORTANT NOTICE:",
        ruleDesc: "Kickoff for the U-17 Boy's NAPPS inter school tournament begins, October 19th- October 26th, 2024 ",
        subRule1: "at Yaba College of Technology."
      }
    ],
    id: "tournament_2_abcxq1z",
  },
  {
    title: "Cost/fee",
    desc: "What does it cost",
    content: [
      {
        ruleTitle: "The Cost and Fee.",
        ruleDesc: "NAPPS Tournament Registration fee per School team is N300,000 (Three hundred thousand naira only)",
        subRule1: "Kindly go to our Accounts page to make payments once decisions have been made. Thank you"
      }
    ],
    id: "tournament_3_abcljkqop",
  }
]
